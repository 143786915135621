<template>
  <div
    class="container inside-wrapper"
    id="projects"
    data-aos="fade-up"
    data-aos-duration="1000"
  >
    <div class="row back-image back-white" style="">
      <div class="col-md-5 col-heading">
        <h2 class="heading wow text-blue">
          Projects <span class="lnr lnr-crop"></span>
        </h2>
        <p
          class="text-dark wow text-dark subheading text-justify"
        >
          We realize each project is different for every client and the needs of
          each client takes priority. By utilizing resources in small teams,
          we've developed a workflow that handles designing, architecting,
          development and implementing solutions quickly and elegantly.
          <br />
        </p>

        <p
          class="text-dark wow text-dark subheading text-justify"
        >
          Our clients can be found in various industries including insurance,
          finance, engineering, municipality, traffic safety, real estate as
          well as many non-profit organizations.
          <br /><br />
        </p>

        <a href="#clients" class="btn-blue">Read More</a>
      </div>
    </div>
    <div class="row projectbox">
      <div class="col-sm-4 min-height col-img">
        <img src="../assets/img/artivist-logo.png" class="img-responsive" />
      </div>
      <div class="col-sm-8 col-pad">
        <h4 class="text-red text-bold">The Artivist Foundation</h4>

        <p class="text-dark p-margin">
         The Artivist Foundation is a nonprofit organization empowering artists around the world to showcase their work for various causes. The application was built using our <a href="https://www.flexiblesites.com" target="_blank">FlexibleSites</a> service so that members can share their works of art and discuss the causes the artivists are looking to support.
        </p>
        <a href="https://artivistfoundation.org/" target="_blank" class="web-red"
          ><span class="web-icon-red"><i class="fa fa-globe"></i> </span>
          <span class="web-text-red text-bold">Visit Website</span></a
        >
      </div>
    </div>
    <div class="row projectbox">
      <div class="col-sm-8 col-pad">
        <h4 class="text-red text-bold">LITTLE RED FASHION</h4>

        <p class="text-dark p-margin">
          The founders of this startup want to be the first comprehensive fashion education solution just for kids by building a platform for empowering the next generation of fashion lovers, leaders, and creatives. Learn more about their philosophy and the project.
        </p>
        <a href="/littleredfashion" class="web-blue"
        ><span class="web-icon-blue"><i class="fa fa-book"></i> </span>
          <span class="web-text-blue text-bold">Case Study</span></a
        >
      </div>
      <div class="col-sm-4 min-height col-img">
        <img src="../assets/img/littleredfashion.jpg" class="img-responsive" />
      </div>
    </div>

    <div class="row projectbox">
      <a id="clients"></a>
      <div class="col-sm-4 min-height col-img">
        <img src="../assets/img/billionacts-logo.png" class="img-responsive" />
      </div>
      <div class="col-sm-8 col-pad">
        <h4 class="text-red text-bold">BILLIONACTS</h4>

        <p class="text-dark p-margin">
          A thoughtful action that spreads more peace in your community, school,
          business or organization, and is designed to impact one or more of the
          Billion Acts Issue Areas that are critical to creating world peace.
        </p>
        <a href="/billionacts" class="web-red"
          ><span class="web-icon-red"><i class="fa fa-book"></i> </span>
          <span class="web-text-red text-bold">Case Study</span></a
        >
      </div>
    </div>

    <div class="row projectbox">
      <div class="col-sm-4 col-sm-push-8 min-height col-img">
        <img
          src="../assets/img/casestudy/case-bee.png"
          class="img-responsive"
        />
      </div>

      <div class="col-sm-8 col-sm-pull-4 col-pad">
        <h4 class="text-blue text-bold">BullyBeeGone</h4>

        <p class="text-dark p-margin">
          BullyBeeGone is a mobile application created from the collaboration of
          DoSomething Strategic and Ocean Ring Technologies for both iOS and
          Android devices.
        </p>

        <router-link to="/casestudy/bullybeegone" class="web-blue">
          <span class="web-icon-blue"><i class="fa fa-book"></i> </span>
          <span class="web-text-red text-bold">Case Study</span>
        </router-link>
      </div>
    </div>

    <div class="row projectbox">
      <a id="clients"></a>
      <div class="col-sm-4 min-height col-img">
        <img src="../assets/img/projects_logo7.jpg" class="img-responsive" />
      </div>
      <div class="col-sm-8 col-pad">
        <h4 class="text-red text-bold">SITTINONMUSIC</h4>

        <p class="text-dark p-margin">
          SittinOnMusic makes it easy to keep up with your fans, by tracking and
          keeping you connected to the users sampling your beats. LOOKING FOR
          BEATS? As an artist you can compose lyrics, sing or rap to any of the
          original beats from the FREE or paid production catalogs. GOT BEATS?
          Set-up a catalog, connect with other artists and share your favorite
          music.
        </p>
        <router-link to="/casestudy/sittinonmusic" class="web-red">
          <span class="web-icon-red"><i class="fa fa-book"></i> </span>
          <span class="web-text-red text-bold">Case Study</span>
        </router-link>
      </div>
    </div>

    <div class="row projectbox">
      <div class="col-sm-4 col-sm-push-8 min-height col-img">
        <img src="../assets/img/projects_logo3.jpg" class="img-responsive" />
      </div>

      <div class="col-sm-8 col-sm-pull-4 col-pad">
        <h4 class="text-blue text-bold">ADVISOR BOB</h4>
        <p class="text-dark p-margin">
          Revolutionary financial advisor software application that allows firms
          to move away from excel using a soluction that tracks cost, expenses,
          goals and buy/sell ratios for each of their advisors. One of the first
          RIA software systems to include custom transactional importing from
          multiple sources including Salesforce.
        </p>
        <a href="/advisorbob" class="web-blue"
          ><span class="web-icon-blue"><i class="fa fa-book"></i> </span>
          <span class="web-text-blue text-bold">Case Study</span></a
        >
      </div>
    </div>

    <div class="row projectbox">
      <div class="col-sm-4 min-height col-img">
        <img src="../assets/img/project_logo7.jpg" class="img-responsive" />
      </div>
      <div class="col-sm-8 col-pad">
        <h4 class="text-blue text-bold">Springboard Collaborative</h4>

        <p class="text-dark p-margin">
          Springboard is a mobile app for both Android and iOS devices that
          helps families build home reading habits by setting goals, making
          plans, and choosing rewards. Weekly, you'll set goals for how often
          and how long to read at home. Springboard helps you plan the ‘who,
          what, when, where, and how’ of reading together. The app will also
          track your progress so that you can celebrate reaching your goals!
        </p>
        <router-link to="/casestudy/springboard" class="web-blue">
          <span class="web-icon-blue"><i class="fa fa-book"></i> </span>
          <span class="web-text-blue text-bold">Case Study</span>
        </router-link>
      </div>
    </div>

    <div class="row projectbox">
      <div class="col-sm-4 col-sm-push-8 min-height col-img">
        <img src="../assets/img/idaay-new.jpg" class="img-responsive" />
      </div>

      <div class="col-sm-8 col-sm-pull-4 col-pad">
        <h4 class="text-red text-bold">
          International Development of African-American Youth (IDAAY)
        </h4>

        <p class="text-dark p-margin">
          IDAAY provides educational and cultural programs as well as
          prevention-intervention social services for youth, their families, and
          the broader community via a combination of carefully designed
          programs. ORT is proud to provide technical consulting and digital
          marketing to IDAAY.
        </p>
         <router-link to="/casestudy/idaay" class="web-red"
          ><span class="web-icon-red"><i class="fa fa-book"></i> </span>
          <span class="web-text-red text-bold">Case Study</span>
        </router-link>
      </div>
    </div>

    <div class="row projectbox">
      <a id="clients"></a>
      <div class="col-sm-4 min-height col-img">
        <img src="../assets/img/projects_logo4.jpg" class="img-responsive" />
      </div>
      <div class="col-sm-8 col-pad">
        <h4 class="text-red text-bold">MUNILOGIC</h4>

        <p class="text-dark p-margin">
          MuniLogic's Core System is a municipal management system consisting of
          a set of modules which provides the foundation for efficient
          operations and expanded functionality. This project requires an update
          to use current technology as well as be able to reach a wider range of
          equipment.
        </p>
       <router-link to="/casestudy/munilogic" class="web-red"
          ><span class="web-icon-red"><i class="fa fa-book"></i> </span>
          <span class="web-text-red text-bold">Case Study</span></router-link
        >
      </div>
    </div>

    <div class="row projectbox">
      <div class="col-sm-4 col-sm-push-8 min-height col-img">
        <img src="../assets/img/touchnj-logo.jpg" class="img-responsive" />
      </div>

      <div class="col-sm-8 col-sm-pull-4 col-pad">
        <h4 class="text-red text-bold">TOUCH NEW JERSEY</h4>

        <p class="text-dark p-margin">
          TOUCH New Jersey, Inc. was formed in 2003 operating as a “shopping
          food pantry” in and around the city of Camden, growing gradually and
          incorporating in 2007. TOUCH New Jersey has been operating in North
          Camden for the past three years and has impacted the local residents
          to the extent that over 10,000 pounds of food is distributed each
          Wednesday.
        </p>
        <router-link to="/casestudy/touchnewjersey/" class="web-red"
          ><span class="web-icon-red"><i class="fa fa-book"></i> </span>
          <span class="web-text-red text-bold">Case Study</span></router-link
        >
      </div>
    </div>

    <div class="row projectbox">
      <div class="col-sm-4 min-height col-img">
        <img src="../assets/img/leacock-logo.jpg" class="img-responsive" />
      </div>

      <div class="col-sm-8 col-pad">
        <h4 class="text-blue text-bold">S. ARCHYE LEACOCK</h4>

        <p class="text-dark p-margin">
          S. Archye Leacock is looking to represent the Philadelphia's 9th
          District, covering parts of Northwest and Northeast communities,
          including East Mt. Airy, West Oak Lane, East Oak Lane, Olney,
          Lawncrest, Lawndale, Burholme and Oxford Circle.
        </p>
        <a href="/casestudy/sarchyeleacock/" class="web-blue"
          ><span class="web-icon-blue"><i class="fa fa-book"></i> </span>
          <span class="web-text-red text-bold">Case Study</span></a
        >
      </div>
    </div>

    <div class="row projectbox">
      <div class="col-sm-4 col-sm-push-8 min-height col-img">
        <img src="../assets/img/projects_logo2.jpg" class="img-responsive" />
      </div>
      <div class="col-sm-8 col-sm-pull-4 col-pad">
        <h4 class="text-red text-bold">
          PENNSYLVANIA SURPLUS LINES ASSOCIATION
        </h4>

        <p class="text-dark p-margin">
          Legacy web application that was rewritten using current technology
          methodologies including test driven development to ensure any existing
          functionality was not lost during the rebuild. Enhancements were
          implemented as the previous codebase was not as efficient as required
          for the organization.
        </p>
        <a href="http://www.pasla.org" class="web-red" target="_blank"
          ><span class="web-icon-red"><i class="fa fa-globe"></i> </span>
          <span class="web-text-red text-bold">Visit Website</span></a
        >
      </div>
    </div>

    <div class="row projectbox">
      <div class="col-sm-4 min-height col-img">
        <img src="../assets/img/projects_logo1.jpg" class="img-responsive" />
      </div>
      <div class="col-sm-8 col-pad">
        <h4 class="text-red text-bold">V2 Properties</h4>

        <p class="text-dark p-margin">
          V2 Properties is an analysis based real estate development company
          that closely studies market conditions so that they are able to make
          prudent land and property acquisitions.
        </p>
        <a href="https://www.v2prop.com" class="web-red" target="_blank"
          ><span class="web-icon-red"><i class="fa fa-globe"></i> </span>
          <span class="web-text-blue text-bold">Visit Website</span></a
        >
      </div>
    </div>

    <div class="row contact-fourth back-red">
      <div class="col-sm-5 bottom-links text-left">
        <router-link to="/">
          <h2 class="heading text-light">
            <i class="fa fa-long-arrow-left"> </i> Home
          </h2>
        </router-link>
      </div>
      <div class="col-sm-2">
        <p></p>
      </div>
      <div class="col-sm-5 bottom-links text-right">
        <router-link to="/news">
          <h2 class="heading text-light">
            News <i class="fa fa-long-arrow-right"> </i>
          </h2>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Work",
  async mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>
</style>
